.left-enter.page {
    transform: translate3d(100%,0,0);
  }
  
  .left-enter.left-enter-active.page {
    transform: translate3d(0,0,0);
    transition: all 800ms ease-in-out;
  }
  
  .left-exit.left-exit-active.page {
    transform: translate3d(-100%,0,0);
    transition: all 800ms ease-in-out;
  }
  
  .right-enter.page {
    transform: translate3d(-100%,0,0)
  } 
  
  .right-enter.right-enter-active.page {
    transform: translate3d(0,0,0);
    transition: all 800ms ease-in-out;
  }
  
  .right-exit.right-exit-active.page {
    transform: translate3d(100%,0,0);
    transition: all 800ms ease-in-out;
  }