
body {
  overflow-x:hidden;
  background:#121212;
  width:100vw
}
a{
  text-decoration: none;
}
.logo{
  width:150px
}

nav{
  display:flex;
  justify-content: space-around;
  
  z-index:999
}

.nav-links{
  width:50%;
  display:flex;
  justify-content: space-around;
  text-align: center;
  list-style: none  ;
}
.nav-text{
  font-family: 'Poppins-Regular';
  color:white;
  letter-spacing: 0.1rem;
  font-weight: 100;
  text-decoration: none;
  padding:5px;
  
}
.nav-text.active{
  padding-bottom:3px !important;
  border-bottom: 2px solid white;
}
.page{
  position:absolute;
  left:0;
  right:0;
  will-change: transform;
}
.tsparticles-canvas-el{
  position: absolute !important;
    width: 100vw;
    height: 90vh;
    z-index:-1
    
}
.home-parent{
  display: grid;
  place-items: center;
  height:89vh;
  
}
.span-middle{
  text-align: center;
}
.home-header{
  color:#FF8F00;
  margin:0;
  font-size: 2.5em;
  font-family:'Poppins-Regular';
}
.home-subtext{
  color:#ffffff;
  margin:0;
  font-size: 1.8em;
  font-family:'Poppins-Regular';
}
.about-parent{
display:grid;
grid-template: auto / auto 1fr;
margin-top: 50px;
}
.about-us-header{
  grid-column: 1;
}
.header-text1{
  font-size:80px;
  font-weight: bold;
  line-height: 0.8em;
  margin-left:4vw;
  color:rgba(255,143,0,0.5);
}
.header-text2{
  font-size:80px;
  font-weight: bold;
  line-height: 0.8em;
  margin-left:4vw;
  color: #ff9b00;
}
.about-us-body {
  color:white;
  text-align: justify;
  text-justify: inter-word;
  margin-top:20px;
  font-size: 18px;
  grid-column: 2;
  margin-left:3vw; 
  margin-right:3vw;
}
.cards-parent {
  --repeat: auto-fit;
}
@media (min-width: 600px) {
  .cards-parent {
    --repeat: 2;
  }
}
.cards-parent{
  display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(var(--repeat, auto-fit), minmax(250px, 1fr));
    padding-left:3vw;
    padding-right:3vw;
    
    
}
.card{
  background-color: #222222;
  border-radius: 15px;
  padding-top:12px;
  padding-bottom:12px;
  padding-left:20px;
  padding-right:20px;
}
.team-cards-parent{
  display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding-left:3vw;
    padding-right:3vw;
    padding-bottom:5vh;
    
}
.team-card{
  background-color: #222222;
  text-align: center;
  border-radius: 15px;
  padding-top:12px;
  padding-bottom:12px;
  padding-left:20px;
  padding-right:20px;
}
.team-image{
  width:30%;
}
.team-icon{
  padding-top:2vh;
}
.fa{
  color:#FF8F00
}
.card-title{
  font-size: 30px;
  font-weight: bold;
  color: white;
}
.card-body{
  color:white
}
.project-header-container{
  
  margin-top:50px;
}

.project-card{
  display: flex;
  flex-wrap: wrap;
  background-color: #222222;
  border-radius: 15px;
  margin-left:8vw;
  margin-right:8vw;
  margin-top:6vh;
  margin-bottom:6vh;  
  
}
.project-content-container{
  margin-left:2vw;
  margin-top:3vh;
  text-align: left;
}
.inner-box{
  flex: 0 1 30vw;
}
.project-image{
  flex:0 1 400px;
  padding:3vh;
  max-width: 400px;
 
}
.project-inner-header{
  font-size:25px;
  font-weight:bold;
  color:rgba(255,143,0);
}
.project-inner-header:hover{
  color:rgba(255,143,0,0.5) !important;
}
.project-content{
  color:white;
  font-size: 18px;
}
.contact-container{
  display:grid;
  grid-template: auto / 35vw;
}
.contact-details{
  display:grid;
  grid-template: auto / 50px auto;
  grid-column: 1;
  margin-left:8vw;
  margin-top:3vh;
}
.col1{
  grid-column: 1;
  margin:auto;
  
}
.col2{
  grid-column: 2;
  margin-left:2vw;
}
.contact-title{
  font-size:35px;
  font-weight:bold;
  color:rgba(255,143,0,0.5);
}
.contact-body{
  margin-top:1vh;
  color:white;
  font-size:18px;
}
.follow-parent{
  grid-column: 2;
  margin-top:2vh;
}
.follow-title{
  grid-column: 1;
}
.follow-icon-container{
  display:flex;
  margin-top:2vh;
}
.icon{
  font-size: 1.5em !important;
  margin-right:3vh;

}
.icon:hover{
  color:rgba(255,143,0,0.5) !important;
}
@media only screen and (min-width: 701px) and (max-width: 1200px)
{
  .contact-container{
    display:grid;
  grid-template: auto / 50vw;
  }
}

@media only screen and (max-width: 700px) {
  
  
  .home-header{
    font-size: 2em;
    }
  .home-subtext{
    font-size: 1.5em;
  }
  .about-parent{
    display:grid;
    grid-template: auto / auto;
    place-items: center;
    margin-top: 50px;
    }
  .header-text1{
    margin:0;
    font-size:3.5em;
  }
  .header-text2{
    margin:0;
    font-size:3.5em;
    }
  .about-us-body {
    font-size: 18px;
    grid-column: 1;
  }
  .cards-parent{
    display:grid;
    grid-column: 1;
    margin-top:4vh;
    grid-template-columns: auto/auto auto;
    padding-left:3vw;
    padding-right:3vw;
    
  }
  .card{
    background-color: #222222;
    border-radius: 15px;
    padding-top:12px;
    padding-bottom:12px;
    padding-left:20px;
    padding-right:20px;
    
    
  }
  .card-title{
    font-size: 20px;
  }
  .card-body{
    font-size:16px;
  }
  .project-header-container{
    text-align:center;
  }
  .project-inner-header{
    font-size:20px;
  }
  .project-card{
    display: grid;
    grid-template-columns: auto/auto;
    place-items: center;
    margin-left:2vw;
    margin-right:2vw;
    width:96vw;
    margin-top:4vh;
    margin-bottom:0;
  }
  .project-content-container{
    margin:0;
    margin-bottom:3vh;
    text-align:center
  }
  .last{
    margin-bottom:5vh;
  }
  .project-image{
    max-width:80vw;
  }
  .project-content{
    font-size: 18px;
  }
  .contact-container{
    display:grid;
  grid-template: auto / 100vw;
    margin-top:5vh;
  }
  .contact-title{
    font-size:25px;
  }
  .contact-body{
    font-size:18px;
  }
  .follow-parent{
    grid-column: 1;
    margin-left:24vw;
    margin-top:3vw;
    margin-bottom:10vh;
  }
  .nav-links {
    padding:0;
    width:95vw;
  }
  a{
    -webkit-tap-highlight-color: transparent;
  }
}

